import { render, staticRenderFns } from "./allMarket.vue?vue&type=template&id=4b6f6aa9&scoped=true"
import script from "./allMarket.vue?vue&type=script&lang=js"
export * from "./allMarket.vue?vue&type=script&lang=js"
import style0 from "./allMarket.vue?vue&type=style&index=0&id=4b6f6aa9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6f6aa9",
  null
  
)

export default component.exports